/* eslint-disable  */
import { CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import useStyles from './ClientReports.style';
import ajax from '../../../utils/ajaxHelper';
import useAuth from '../../../hooks/useAuth';
import axios from 'axios';
import { API_BASE_URL } from '../../../constants/globalConstants';

const ClientReports = () => {
  const [fileReady, setFileReady] = useState(false);
  const [fileName, setFileName] = useState('');
  const { fetchCompanyId } = useAuth();

  const generateClientReportInCsv = async () => {
    try {
      const companyId = await fetchCompanyId();

      const response = await ajax.post(
        'generate-client-report-in-csv',
        {
          companyId: companyId,
          isSolo: true,
          currentPage: 0,
          itemsPerPage: 10,
          searchString: '',
          orderBy: 'First Name',
          orderDirection: 'asc',
        },
        {
          networkParams: { showLoader: false },
        },
      );

      if (response && response.status == true) {
        setFileName(response.filename);
        setFileReady(true);
      }
    } catch (error) {
      console.log('reportDownloadHandler = error = ', error);
    }
  };

  useEffect(() => {
    generateClientReportInCsv();
  }, []);

  const downloadGeneratedClientReportInCsv = async (e) => {
    e.preventDefault();

    let query_url = '';
    if (window.location.hostname === 'localhost') {
      query_url = `download-generated-client-report-in-csv/${fileName}`;
    } else {
      query_url = `api/download-generated-client-report-in-csv/${fileName}`;
    }

    try {
      const response = await axios.get(query_url, {
        responseType: 'blob',
        networkParams: { showLoader: false },
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `client-report.csv`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log('downloadGeneratedClientReportInCsv error = ', error);
    }
  };

  return (
    <div>
      <h1>
        Your file is being processed, the download link will appear below once the file is ready.
        This process may take few minutes.
      </h1>
      {fileReady === true ? (
        <>
          <input
            variant="contained"
            color="primary"
            style={{
              width: '150px',
              height: '30px',
              padding: '5px 15px',
              cursor: 'pointer',
              background: '#4860CF',
              color: 'white',
              borderRadius: '25px',
              border: 'none',
              fontSize: '15px',
            }}
            type="button"
            value="Download Report"
            className="downloadButton"
            onClick={(e) => {
              downloadGeneratedClientReportInCsv(e);
            }}
          />
        </>
      ) : (
        <>
          <CircularProgress />
        </>
      )}
    </div>
  );
};

export default ClientReports;
