/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-const */
/* eslint-disable no-underscore-dangle */
/* eslint-disable  */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
/* eslint-disable global-require */
/* eslint-disable  */
import Cookies from 'js-cookie';
import dotenv from 'dotenv';
import { roles } from '../constants/globalConstants';

dotenv.config();

export const getHostType = (roleId) => {
  switch (roleId) {
    case 'admin':
      return roles.Company;
    case 'solo-instructor':
      return roles.SoloInstructor;
    case 'user':
      return roles.Client;
    case 'solo-client':
      return roles.SoloClient;
    default:
      return roles.Uber;
  }
};

export const getCompanyId = () => {
  const user = Cookies.get('user');
  let companyId;
  if (user) {
    const parsedUser = JSON.parse(atob(user));
    companyId = parsedUser.companyId || parsedUser?.soloInstructorId;
  }
  return companyId;
};

export const getClientSignupDetailsId = () => {
  const user = Cookies.get('user');
  let clientSignupDetailsId;
  if (user) {
    const pardedUser = JSON.parse(atob(user));
    clientSignupDetailsId = pardedUser?.clientSignupDetailsId;
  }
  return clientSignupDetailsId;
};

export const waiverAndContractFilePath = (pdfLink, signedPDFLink) => {
  const dir = `uploads/original/`;
  const path = signedPDFLink ? signedPDFLink : pdfLink;

  let link = null;

  if (window.location.hostname === 'localhost') {
    link = `${process.env.REACT_APP_LOCAL_BACKEND_URL}/${dir}${path}`;
  } else {
    link = `${window.location.protocol}//${window.location.host}/api/${dir}${path}`;
  }
  return { path, link };
};
export const isSolo = () => {
  const user = Cookies.get('user');
  const parsedUser = user ? JSON.parse(atob(user)) : null;
  return (
    window.location.href.includes('solo-instructor') ||
    window.location.href.includes('solo-client') ||
    !!parsedUser?.soloInstructorId
  );
};

export const isWidgetPage = () => {
  return window.location.href.includes('solo-instructor');
};
export const getUserId = () => {
  const user = Cookies.get('user');
  let userId;
  if (user) {
    const parsedUser = JSON.parse(atob(user));
    userId = parsedUser._id;
  }
  return userId;
};
export const getUser = () => {
  const user = Cookies.get('user');
  return user ? JSON.parse(atob(user)) : null;
};

export const showErrIfTouched = (key, errors, touched) => {
  return touched[key] && errors[key];
};

export const validateBadInput = (e, setFieldValue) => {
  if (e.keyCode !== 187 && e.keyCode !== 189 && e.keyCode !== 69) {
    setFieldValue(e.target.name, e.target.value);
  } else {
    e.preventDefault();
  }
};

export const isFile = (input) => 'File' in window && input instanceof File;

/* @Added by Faisal Khan @Desc - react-number-format reset to 1 digit after entering 23 digits */
export const limit20 = (val, excludeZero = false) => {
  const { formattedValue, floatValue } = val;
  return (
    formattedValue === '' ||
    (floatValue <= 99999999999999999999 &&
      ((excludeZero && floatValue > 0) || (!excludeZero && floatValue >= 0)))
  );
};

/* @Added by Faisal Khan @Desc - isValidDate */
export function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

/* @Added by Faisal Khan @Desc - filterDate */
export function filterDate(d) {
  if (d === null || d === '') return null;
  return d instanceof Date && !isNaN(d) ? d : null;
}
/* @Added by Faisal Khan @Desc - filterDate */
export function isValidMongoId(id) {
  return new RegExp('^[0-9a-fA-F]{24}$').test(id);
}

/* @Added by Saurabh Pandey @Desc - phoneFormat to (XXX) XXX-XXXX */
export const phoneFormat = (input, props, setFieldValue, fieldName) => {
  // Strip all characters from the input except digits

  input = input.replace(/\D/g, '');

  // Trim the remaining input to ten characters, to preserve phone number format
  input = input.substring(0, 10);
  // Based upon the length of the string, we add formatting as necessary
  var size = input.length;
  if (size == 0) {
    input = input;
  } else if (size < 4) {
    input = input;
  } else if (size < 7) {
    input = '(' + input.substring(0, 3) + ') ' + input.substring(3, 6);
  } else {
    input =
      '(' + input.substring(0, 3) + ') ' + input.substring(3, 6) + '-' + input.substring(6, 10);
  }
  if (fieldName) {
    setFieldValue(fieldName, input);
  }
  if (props) {
    props.onChange(input);
  }
};

/* @Added by Saurabh Pandey @Desc - phoneFormat to (XXX) XXX-XXXX */
export const phoneNumberFormat = (input) => {
  // Strip all characters from the input except digits

  input = input.replace(/\D/g, '');

  // Trim the remaining input to ten characters, to preserve phone number format
  input = input.substring(0, 10);
  // Based upon the length of the string, we add formatting as necessary
  var size = input.length;
  if (size == 0) {
    input = input;
  } else if (size < 4) {
    input = input;
  } else if (size < 7) {
    input = '(' + input.substring(0, 3) + ') ' + input.substring(3, 6);
  } else {
    input =
      '(' + input.substring(0, 3) + ') ' + input.substring(3, 6) + '-' + input.substring(6, 10);
  }
  return input;
};

/* @Added by Saurabh Pandey @Desc - phoneFormat from (XXX) XXX-XXXX */
export const phoneReformat = (input) => {
  // Strip all characters from the input except digits

  input = input.replace(/[^\d]/g, '');

  // Trim the remaining input to ten characters, to preserve phone number format
  input = input.substring(0, 10);
  // Based upon the length of the string, we add formatting as necessary
  return input;
};

export const timeFormat = (input, props, setFieldValue, fieldName) => {
  // Strip all characters from the input except digits
  let extraInput = input;
  input = input.replace(/\D/g, '');
  input = input.replace(/:/g, '');
  input = input.substring(0, 4);
  var size = input.length;
  if (size < 3) {
    input = input;
  } else if (size == 3) {
    if (extraInput.substr(extraInput.indexOf(':') + 1).length == 2) {
      input = input.substring(0, 1) + ':' + input.substring(1, 3);
    } else {
      input = input.substring(0, 2) + ':' + input.substring(2, 4);
    }
  } else {
    input = input.substring(0, 2) + ':' + input.substring(2, 4);
  }

  if (fieldName) {
    setFieldValue(fieldName, input);
  }
  if (props) {
    props.onChange(input);
  }
};
