/* eslint-disable */
import React, { useEffect, useCallback, useContext } from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Cookies from 'js-cookie';
import ajax from './ajaxHelper';
import { NetworkContext } from '../contexts/networkContexts';
import { NETWORK_EVENTS } from '../constants/actionTypes';
import Loader from '../components/Loader/Loader';
import useAuth from '../hooks/useAuth';

/**
 * AjaxIterceptor created for global loader and global error handler
 *
 * @returns
 */
const AjaxInterceptors = () => {
  const [state, dispatch] = useContext(NetworkContext);

  const { logoutNew } = useAuth();

  const onRequest = useCallback(
    (request) => {
      const hosttype = Cookies.get('hosttype');
      let hostTypeDetails = {};
      if (hosttype) {
        const parsedHostType = atob(hosttype);
        hostTypeDetails = {
          hosttype: parsedHostType,
        };
      }

      const token = Cookies.get('token');
      let authorization = {};
      if (token) {
        authorization = {
          Authorization: `Bearer ${token}`,
        };
      }
      const updatedRequest = {
        ...request,
        headers: {
          ...request.headers,
          ...authorization,
          ...hostTypeDetails,
        },
      };
      dispatch({
        type: NETWORK_EVENTS.NETWORK_CALL_REQUEST,
        payload: request.networkParams || {},
      });
      return updatedRequest;
    },
    [dispatch],
  );

  const onRequestError = useCallback(
    (error) => {
      dispatch({
        type: NETWORK_EVENTS.NETWORK_CALL_FAIL,
        payload: error,
      });
      return Promise.reject(error);
    },
    [dispatch],
  );

  const onResponse = useCallback(
    (response) => {
      if (!response.data.status) {
        dispatch({
          type: NETWORK_EVENTS.NETWORK_CALL_FAIL,
          payload: new Error(response.data.message ?? 'Something went wrong'),
        });
        return Promise.reject(new Error(response.data.message ?? 'Something went wrong'));
      }
      if (!response.config.skipSuccessMessage) {
        dispatch({
          type: NETWORK_EVENTS.NETWORK_CALL_SUCCESS,
          payload: response.data.message,
        });
      }
      return response.data;
    },
    [dispatch],
  );

  const onResponseError = useCallback(
    (error) => {
      dispatch({
        type: NETWORK_EVENTS.NETWORK_CALL_FAIL,
        payload: error,
      });
      return Promise.reject(error);
    },
    [dispatch],
  );

  const handleClose = useCallback(() => {
    dispatch({ type: NETWORK_EVENTS.NETWORK_CALL_RESET });
  }, [dispatch]);

  useEffect(() => {
    const requestInterceptor = ajax.interceptors.request.use(onRequest, onRequestError);
    const responseInterceptor = ajax.interceptors.response.use(onResponse, onResponseError);

    return () => {
      ajax.interceptors.request.eject(requestInterceptor);
      ajax.interceptors.response.eject(responseInterceptor);
    };
  }, [onRequest, onRequestError, onResponse, onResponseError]);

  return (
    <Choose>
      <When condition={state.loading && state.showLoader}>
        <Loader {...{ txt: state.loaderTxt }} />
      </When>
      <When condition={!!state.error && state.count === 0}>
        <Snackbar open={!!state.error} autoHideDuration={5000} onClose={handleClose}>
          <Alert elevation={6} variant="filled" onClose={handleClose} severity="error">
            {state.error.message}
          </Alert>
        </Snackbar>
      </When>
      <When condition={!!state.success && state.count === 0}>
        <Snackbar open={!!state.success} autoHideDuration={5000} onClose={handleClose}>
          <Alert elevation={6} variant="filled" onClose={handleClose} severity="success">
            {state.success}
          </Alert>
        </Snackbar>
      </When>
    </Choose>
  );
};

export default AjaxInterceptors;
