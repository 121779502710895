/* eslint-disable */
/* eslint-disable no-underscore-dangle */
import { PROMOCODE_EVENTS, API_EVENTS } from '../constants/actionTypes';

export const initialState = {
  promoModal: false,
  loading: false,
  error: null,
  data: [],
  editData: [],
};

const promoCodeReducer = (state, { type, payload }) => {
  switch (type) {
    case PROMOCODE_EVENTS.LOAD_PROMOCODE_LIST:
      return { ...state, loading: false, error: false, data: payload };

    case PROMOCODE_EVENTS.DELETE_PROMOCODE:
      const res = {
        ...state,
        loading: false,
        error: false,
        data: state?.data.filter((x) => x._id.toString() !== payload.toString()),
      };

      return res;
    case PROMOCODE_EVENTS.LOAD_DATA:
      return { ...state, loading: false, error: false, editData: payload };
    case API_EVENTS.REQUEST:
      return { ...state, loading: true, error: false };

    case API_EVENTS.SUCCESS: {
      return { ...state, loading: false, data: payload, error: false };
    }

    case API_EVENTS.DONE: {
      return { ...state, loading: false, error: false };
    }

    case API_EVENTS.FAIL:
      return { ...state, loading: false, error: payload };

    case API_EVENTS.RESET:
      return initialState;

    case PROMOCODE_EVENTS.SET_MODAL: {
      return { ...state, loading: false, ...payload, promoModal: payload?.promoModal };
    }

    case PROMOCODE_EVENTS.RESET_MODAL: {
      return { ...state, loading: false, ...payload, promoModal: false };
    }
    default:
      return state;
  }
};

export default promoCodeReducer;
