import { API_EVENTS, REMOVE_MEDIA_EVENTS } from '../constants/actionTypes';

export const initialState = {
  loading: false,
  data: null,
  error: null,
};

const clientWaiverReducer = (state, { type, payload }) => {
  switch (type) {
    case API_EVENTS.REQUEST:
    case REMOVE_MEDIA_EVENTS.REMOVE_REQUEST:
      return { ...state, loading: true };

    case API_EVENTS.SUCCESS:
      return { ...state, loading: false, data: payload };

    case REMOVE_MEDIA_EVENTS.REMOVE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: { ...state.data, waiver: undefined },
      };

    case API_EVENTS.FAIL:
    case REMOVE_MEDIA_EVENTS.REMOVE_FAIL:
      return { ...state, loading: false, error: payload };

    case API_EVENTS.RESET:
      return initialState;

    case 'DELETE_WAIVER_TERM':
      return {
        ...state,
        loading: false,
        data: { ...state.data, term: state.data.term.filter((x) => x.value !== payload) },
      };

    default:
      return state;
  }
};

export default clientWaiverReducer;
